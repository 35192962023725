import { useState, useEffect } from "react";
import front_mobile from "./front24.jpg";
import txt from "./1.txt";

import logo from "./logo2.png";
import back from "./back.png";
import "./App.css";
import React from "react";
import WebFont from "webfontloader";
import useWindowDimensions from "./ScreenWidth";

import ovre1 from "./01_brannstasjon.jpg";
import torget from "./02_torget.jpg";
import kai from "./03_kaien_atg.jpg";
import jotun from "./jotun_stadion.jpg";
import folkets from "./folkets_hus.jpg";
import stadion from "./stadion.jpg";
import sentrum from "./ovre_sentrum.jpg";
import camping  from "./camping.jpg";
import ski from "./skihytte.jpg";
import gamleskulen from "./gamleskulen1.jpg";
import vinter from "./vinter.jpg";
import aksjon from "./aksjon.jpg";
import stemna from "./stemna.jpg";
import ofredalen from "./ofredalen.jpg";

function App() {
  const [img, setImg] = useState("");
  const [images, setImages] = useState([torget,torget,ovre1,kai,folkets,camping,jotun,ski,stadion,sentrum,gamleskulen,vinter,aksjon,stemna,ofredalen]);
  const [open, setOpen] = useState(false);
  const [txtContent, setTxtContent] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');

  const currentDate = new Date();
  const dayOfMonth = currentDate.getDate();
  //const dayOfMonth = 14;
  const { height, width } = useWindowDimensions();

  const cal = {
    backgroundImage: !open && `url(${front_mobile})`,
    backgroundSize: width > 1400 ? "cover" : "contain",
    backgroundPosition: width > 1400 ? "center" : "bottom center",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    backgroundColor: "black",
    alignItems: "center",
    justifyContent: "center",
    bottom: width > 1400 && "20px",
    
  };

  const textframe =  {
    width: width > 577 ? "60%" : "100%",
    position: "relative",
    top: width > 1400 ? 20 : 10,

  
  
  }

  const bullets =  {
    fontSize: width > 1400 ? "12pt" : "12pt",
    marginLeft: 15,
    marginRight: 15,
  
  
  }


  const textBox = {
    top: width > 577 && -40,
    position: "relative",
    background: 'linear-gradient(to bottom, #000, #09111e)',
    width: width > 577 ? "70%" : "100%",
    padding: "20px",
    borderRadius: "5px",  


  };

  const small = {
    fontSize: "9pt",
    
  }

  const cal_days = {
    fontSize: width > 1400 ? "30pt" : width > 577 ? "25pt" : "16pt",
    width: width > 1400 ? "50px" : width > 577 ? "35px" : "15px",
    height: width > 1400 ? "50px" : width > 577 ? "35px" : "15px",
    padding: width > 1400 ? "20px" : width > 577 ? "20px" : "12px",
    margin: width > 1400 ? "10px" : width > 577 ? "10px" : "5px",
  };

  useEffect(() => {

    document.title = 'Adventskalendar - Årdal Sogelag';
    fetch(txt) // Replace with the actual path to your .txt file
      .then((response) => response.text())
      .then((text) => {
        // Set the text content in state
        setTxtContent(text);
      })
      .catch((error) => {
        console.error("Error reading .txt file:", error);
      });

    WebFont.load({
      google: {
        families: ["League Script", "Libre Baskerville", "Unna", "Merriweather","Caveat"],
      },
    });
  }, []);

  function openDay(day) {
    setOpen(day + 1);
    setImg(images[day]);
  }

  function closeDay() {
    setOpen(false);
  }

  function GodJul() {
    return (
      <div
        className={"godjul"}
        style={{
          fontSize: width > 1400 ? "180px" : width > 577 ? "140px" : "85px",
          marginTop: width > 1400 ? "20px" : width > 577 ? "0px" : "10px",
        }}
      >
        <span className={!open && "gd"}>G</span>
        <span className={!open && "o"}>o</span>
        <span className={!open && "gd"}>d</span> &nbsp;
        <span className={!open && "j"}>j</span>
        <span className={!open && "ul"}>ul</span>
        <span className={!open && "e"}>!</span>
      </div>
    );
  }

  function DayImage() {
    return <div style={{backgroundColor: "#000", position: "relative"}}>
      <DayNumber/>
      <a href="#" onClick={closeDay}><img src={images[open]} className="dayImg" /></a></div>;
  }

  function DayText() {
    let read = txtContent.split("#");
    let text = read[open].split("|");
    setHtmlContent(text[2]);

    return <div className="textContainer">
        <div className="linearBlue" style={textBox}>
           
      <div
            className={open == 12 ? "latstaa" : "gold caps"}
            style={{
              fontSize:
                width > 1500
                  ? open == 12
                    ? "80pt"
                    : "34pt"
                  : width > 577
                  ? open == 12
                    ? "50pt"
                    : "34pt"
                  : open == 12
                  ? "35pt"
                  : "16pt",
              marginBottom: 7,
            }}
          >
{text[0]}</div>
            <div className="lightgold caps" style={{fontSize: width > 1400 ? "24pt" : width > 577 ? "18pt" : "14pt", marginBottom: 20}}>{text[1]}</div>
            <div className="textParent">
                <div style={textframe}>
                
                      <div className="white" dangerouslySetInnerHTML={{ __html: htmlContent }}/>
                      <p/><br></br>
                      <div className="white" style={small}>Foto: {text[3]}</div>
                      { text[4] != "" && <div className="white" style={small}>Tekst: {text[4]}</div> }
                      
                      <div className="gold caps" style={{fontSize: width > 1400 ? "34pt" : "20pt" , margin: 7}}><a href="#" onClick={closeDay}><img src={back} style={{height: 30}}/></a></div>
                </div>
            </div>
    
          </div>
        </div>;
  }

  function FrontCredits() {
    return (
      <div className="frontCredits">
        <div
          style={{
            margin: width > 1400 ? 15 : 5,
            textAlign: width > 1400 ? "right" : "center",
            fontSize: width > 1400 ? "10pt" : "8pt",
          }}
        >
          Foto: Tom Børslid
        </div>
      </div>
    );
  }

  function DayNumber() {
    return (
      <div style={{position: "absolute", zIndex: 9, top: width > 1400 ? 50 : width > 577 ? 30 : 20, left: width > 1500 ? 70 : width > 577 ? 30 : 20}}>
        <div className="circle" style={{  width: width > 1400 ? 120 : width > 577 ? 100 : 50, height: width > 1500 ? 120 : width > 577 ? 100 : 50}}>
          <div style={{fontSize: width > 1400 ? 60 : width > 577 ? 40 : 20, fontFamily: "Libre Baskerville"}} className="lightgold">{open}</div>
        </div>
      </div>
    );
  }

  function Calendar() {
    return (
      <div
        className={open ? "calendar hide" : "calendar"}
        style={{
          bottom: width > 1400 && 150,
          top: width < 1400 && (width > 577 ? 270 : 200),
        }}
      >
        <div className="days">
          {width > 1400 ? (

<div>
              <div className="row">
                <CalDays range={"0.8"} />
              </div>
              <div className="row">
                <CalDays range={"8.16"} />
              </div>
              <div className="row">
                <CalDays range={"16.24"} />
              </div>
            </div>

    
          ) : width > 577 ? (
            <div>
              <div className="row">
                <CalDays range={"0.6"} />
              </div>
              <div className="row">
                <CalDays range={"6.12"} />
              </div>
              <div className="row">
                <CalDays range={"12.18"} />
              </div>
              <div className="row">
                <CalDays range={"18.24"} />
              </div>
            </div>
          ) 
          
          :
          (
            <div>
            <div className="row">
              <CalDays range={"0.4"} />
            </div>
            <div className="row">
              <CalDays range={"4.8"} />
            </div>
            <div className="row">
              <CalDays range={"8.12"} />
            </div>
            <div className="row">
              <CalDays range={"12.16"} />
            </div>
            <div className="row">
              <CalDays range={"16.20"} />
            </div>
            <div className="row">
              <CalDays range={"20.24"} />
            </div>
          </div>
          )}
        </div>
      </div>
    );
  }

  function Branding() {
    return (
      <div
        className={open ? "header branding-bg" : "header branding-transparent"}
      >
        <a href="#" onClick={closeDay}>
          <div className="branding">
            <img src={logo} style={{ verticalAlign: "middle" }} />
            <span className="logo">ÅRDAL SOGELAG</span>
          </div>
        </a>
      </div>
    );
  }

  function CalDays(props) {
    const divs = [];
    let r = props.range;
    const range = r.split(".");
    let d = "";

    for (let i = parseInt(range[0]); i < parseInt(range[1]); i++) {
      i < dayOfMonth
        ? (d = (
            <a href="#" onClick={() => openDay(i)}>
              <div
                key={i}
                className={
                  i < dayOfMonth
                    ? (width > 1400
                      ? "c c-active"
                      : "c c-active-m")
                    : (width > 1400
                    ? "c c-passive"
                    : "c c-passive-m")
                }
                style={cal_days}
              >
                {i + 1}
              </div>
            </a>
          ))
        : (d = (
            <div
              key={i}
              className={
                i < dayOfMonth
                  ? width > 1400
                    ? "c c-active"
                    : "c c-active-m"
                  : width > 1400
                  ? "c c-passive"
                  : "c c-passive-m"
              }
              style={cal_days}
            >
              {i + 1}
            </div>
          ));

      divs.push(d);
    }

    return divs;
  }

  function DayBadge() {
    return <></>;
  }

  return (
    <div style={cal} >
      <Branding />
      {open ? (<>
        
        <DayImage/>
        
        <DayText/></>) : (
        <>
          <GodJul />
          <Calendar />
          { width > 577 && <FrontCredits />} 
        </>
      )}
    </div>
  );
}

export default App;
